import React from 'react';
import { Menu } from 'semantic-ui-react';

interface AdminVerticalMenuProps {
  activeItem: string;
  onItemClick: (name: string) => void;
}

const AdminVerticalMenu: React.FC<AdminVerticalMenuProps> = ({ activeItem, onItemClick }) => {
  return (
    <Menu vertical>
      <Menu.Item
        name='users'
        active={activeItem === 'users'}
        onClick={() => onItemClick('users')}
      >
        Users
      </Menu.Item>

      {/* <Menu.Item
        name='createStudents'
        active={activeItem === 'createStudents'}
        onClick={() => onItemClick('createStudents')}
      >
        Create Students
      </Menu.Item> */}
      {/* <Menu.Item
        name='courses'
        active={activeItem === 'courses'}
        onClick={() => onItemClick('courses')}
      >
        Courses
      </Menu.Item>
      <Menu.Item
        name="createCourse"
        active={activeItem === 'createCourse'}
        onClick={() => onItemClick('createCourse')}
      >
        Create Course
      </Menu.Item>
      <Menu.Item
        name="createModule"
        active={activeItem === 'createModule'}
        onClick={() => onItemClick('createModule')}
      >
        Create Module
      </Menu.Item> */}
      {/* Add more admin-specific menu items here */}
    </Menu>
  );
};

export default AdminVerticalMenu;