import React, { useState, useEffect } from 'react';
import { Table, Header, Segment, Loader, Message, Label, Tab, Modal, Button, List, Dropdown, Icon } from 'semantic-ui-react';
import { collection, query, getDocs, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { formatDate } from '../../utils/dateUtils';
import { Timestamp } from 'firebase/firestore';

interface User {
    id: string;
    firstName: string;
    lastName: string;
    initiatedName?: string;
    role: 'student' | 'grader';
}

interface Test {
    id: string;
    testName: string;
    type: 'openBook' | 'closeBook';
    lastSubmissionDate: Timestamp | Date;
}

interface Criteria {
    description: string;
    grade: number;
    actualGrade?: number;
}

interface Submission {
    studentId: string;
    submittedAt?: Date;
    assignedGraderId?: string;
    assignedAt?: Date;
    gradingStatus?: 'pending' | 'graded';
    grade?: number;
    requiresRewrite?: boolean;
    essay?: string;
    criteriaGrades?: Criteria[];
    graderComments?: string;
}

interface TestStatus {
    test: Test;
    submissions: Record<string, Submission>; // keyed by studentId
}

type FilterType = 'all' | 'notSubmitted' | 'notGraded';

const ViewStatus: React.FC<{ moduleId: string }> = ({ moduleId }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [students, setStudents] = useState<User[]>([]);
    const [graders, setGraders] = useState<Record<string, User>>({});
    const [testStatuses, setTestStatuses] = useState<TestStatus[]>([]);
    const [selectedSubmission, setSelectedSubmission] = useState<Submission | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeFilter, setActiveFilter] = useState<FilterType>('all');

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch module users (students)
                const moduleDoc = await getDoc(doc(db, 'modules', moduleId));
                const moduleUsers = moduleDoc.data()?.users || [];
                
                // Fetch all users' details
                const usersSnapshot = await getDocs(collection(db, 'users'));
                const usersMap = new Map<string, User>();
                usersSnapshot.docs.forEach(doc => {
                    const userData = doc.data() as User;
                    usersMap.set(doc.id, { ...userData, id: doc.id });
                });

                // Filter students and graders
                const studentsList: User[] = [];
                const gradersMap: Record<string, User> = {};
                
                moduleUsers.forEach((userId: string) => {
                    const user = usersMap.get(userId);
                    if (user) {
                        if (user.role === 'student') {
                            studentsList.push(user);
                        } else if (user.role === 'grader') {
                            gradersMap[userId] = user;
                        }
                    }
                });

                setStudents(studentsList);
                setGraders(gradersMap);

                // Fetch tests
                const testsSnapshot = await getDocs(collection(db, 'modules', moduleId, 'tests'));
                const testStatusPromises = testsSnapshot.docs.map(async testDoc => {
                    const testData = testDoc.data();
                    const test = {
                        id: testDoc.id,
                        ...testData,
                        lastSubmissionDate: testData.lastSubmissionDate
                    } as Test;
                    
                    // Fetch submissions for this test
                    const submissionsSnapshot = await getDocs(
                        collection(db, 'modules', moduleId, 'tests', test.id, 'submissions')
                    );
                    
                    const submissions: Record<string, Submission> = {};
                    submissionsSnapshot.docs.forEach(subDoc => {
                        const subData = subDoc.data();
                        if (subData.answers?.[0]) { // Assuming first answer is what we want
                            submissions[subDoc.id] = {
                                studentId: subDoc.id,
                                submittedAt: subData.answers[0].submittedAt?.toDate(),
                                assignedGraderId: subData.answers[0].assignedGraderId,
                                assignedAt: subData.answers[0].assignedAt?.toDate(),
                                gradingStatus: subData.answers[0].gradingStatus,
                                grade: subData.answers[0].grade,
                                requiresRewrite: subData.answers[0].requiresRewrite,
                                essay: subData.answers[0].essay,
                                criteriaGrades: subData.answers[0].criteriaGrades,
                                graderComments: subData.answers[0].graderComments,
                            };
                        }
                    });

                    return { test, submissions };
                });

                const testStatuses = await Promise.all(testStatusPromises);
                setTestStatuses(testStatuses);
                
            } catch (err) {
                console.error('Error fetching status data:', err);
                setError('Failed to load status data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [moduleId]);

    // Add this function to sort and filter students
    const getFilteredStudents = (students: User[], submissions: Record<string, Submission>) => {
        // First, sort students by first name
        const sortedStudents = [...students].sort((a, b) => 
            a.firstName.localeCompare(b.firstName)
        );

        // Then apply filters
        return sortedStudents.filter(student => {
            const submission = submissions[student.id];
            switch (activeFilter) {
                case 'notSubmitted':
                    return !submission;
                case 'notGraded':
                    return submission && submission.gradingStatus !== 'graded';
                default:
                    return true;
            }
        });
    };

    const filterOptions = [
        { key: 'all', text: 'All Students', value: 'all' },
        { key: 'notSubmitted', text: 'Not Submitted', value: 'notSubmitted' },
        { key: 'notGraded', text: 'Not Graded', value: 'notGraded' }
    ];

    const calculateGradingDeadline = (lastSubmissionDate: Timestamp | Date) => {
        // Convert Timestamp to Date if needed
        const submissionDate = lastSubmissionDate instanceof Timestamp 
            ? lastSubmissionDate.toDate() 
            : lastSubmissionDate;
            
        const deadline = new Date(submissionDate);
        deadline.setDate(deadline.getDate() + 14); // Add 14 days
        return deadline;
    };

    const renderSubmissionModal = () => (
        <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            size="large"
        >
            <Modal.Header>
                Student Submission
                {selectedSubmission?.gradingStatus === 'graded' && (
                    <Label color="green" style={{ marginLeft: '1rem' }}>
                        Graded
                    </Label>
                )}
            </Modal.Header>
            <Modal.Content>
                <Header as="h4">Submitted Essay</Header>
                <Segment style={{ whiteSpace: 'pre-wrap' }}>
                    <div dangerouslySetInnerHTML={{ __html: selectedSubmission?.essay || '' }} />
                </Segment>

                {selectedSubmission?.gradingStatus === 'graded' && (
                    <>
                        <Header as="h4">
                            Grade: {selectedSubmission.grade}
                            {selectedSubmission.requiresRewrite && (
                                <Label color="red" style={{ marginLeft: '1rem' }}>
                                    Requires Rewrite
                                </Label>
                            )}
                        </Header>

                        <Header as="h4">Criteria Grades</Header>
                        <List>
                            {selectedSubmission.criteriaGrades?.map((criterion, index) => (
                                <List.Item key={index}>
                                    <List.Content>
                                        <List.Header>{criterion.description}</List.Header>
                                        <List.Description>
                                            Grade: {criterion.actualGrade} / {criterion.grade}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                            ))}
                        </List>

                        <Header as="h4">Grader Comments</Header>
                        <Segment>
                            {selectedSubmission.graderComments || 'No comments provided'}
                        </Segment>
                    </>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setIsModalOpen(false)}>Close</Button>
            </Modal.Actions>
        </Modal>
    );

    const renderTestTable = (testStatus: TestStatus) => {
        const filteredStudents = getFilteredStudents(students, testStatus.submissions);
        
        return (
            <>
                <Segment>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <div>
                            <Dropdown
                                selection
                                options={filterOptions}
                                value={activeFilter}
                                onChange={(_, data) => setActiveFilter(data.value as FilterType)}
                                label="Filter"
                            />
                            <Label style={{ marginLeft: '1rem' }}>
                                Showing {filteredStudents.length} of {students.length} students
                            </Label>
                        </div>
                        <div style={{ textAlign: 'right' }}>
                            <div style={{ marginBottom: '0.5rem' }}>
                                <Label size="large" color="blue">
                                    <Icon name="calendar" />
                                    Last Submission Date: {
                                        testStatus.test.lastSubmissionDate instanceof Timestamp 
                                            ? formatDate(testStatus.test.lastSubmissionDate.toDate())
                                            : formatDate(testStatus.test.lastSubmissionDate)
                                    }
                                </Label>
                            </div>
                            <div>
                                <Label size="large" color="orange">
                                    <Icon name="clock" />
                                    Grading Deadline: {formatDate(calculateGradingDeadline(testStatus.test.lastSubmissionDate))}
                                </Label>
                            </div>
                        </div>
                    </div>
                </Segment>

                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Student</Table.HeaderCell>
                            <Table.HeaderCell>Submission Status</Table.HeaderCell>
                            <Table.HeaderCell>Submission Date</Table.HeaderCell>
                            <Table.HeaderCell>Assigned To</Table.HeaderCell>
                            <Table.HeaderCell>Assigned Date</Table.HeaderCell>
                            <Table.HeaderCell>Grading Status</Table.HeaderCell>
                            <Table.HeaderCell>Grade</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {filteredStudents.map(student => {
                            const submission = testStatus.submissions[student.id];
                            return (
                                <Table.Row key={student.id}>
                                    <Table.Cell>
                                        {student.initiatedName ? `${student.initiatedName}` : `${student.firstName} ${student.lastName}`}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {submission ? (
                                            <Label color="green">Submitted</Label>
                                        ) : (
                                            <Label>Not Submitted</Label>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {submission?.submittedAt && formatDate(submission.submittedAt)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {submission?.assignedGraderId && graders[submission.assignedGraderId] ? (
                                            `${graders[submission.assignedGraderId].firstName} ${graders[submission.assignedGraderId].lastName}`
                                        ) : '-'}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {submission?.assignedAt && formatDate(submission.assignedAt)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {submission?.gradingStatus && (
                                            <Label color={submission.gradingStatus === 'graded' ? 'green' : 'yellow'}>
                                                {submission.gradingStatus}
                                            </Label>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {submission?.grade !== undefined && (
                                            <>
                                                {submission.grade}
                                                {submission.requiresRewrite && (
                                                    <Label color="red" size="tiny" style={{ marginLeft: '0.5em' }}>
                                                        Rewrite
                                                    </Label>
                                                )}
                                            </>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {submission && (
                                            <Button
                                                primary
                                                size="small"
                                                onClick={() => {
                                                    setSelectedSubmission(submission);
                                                    setIsModalOpen(true);
                                                }}
                                            >
                                                View Submission
                                            </Button>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            );
                        })}
                    </Table.Body>
                </Table>
            </>
        );
    };

    const panes = testStatuses.map(testStatus => ({
        menuItem: testStatus.test.testName,
        render: () => (
            <Tab.Pane>
                <Header as="h3">{testStatus.test.testName}</Header>
                {renderTestTable(testStatus)}
            </Tab.Pane>
        )
    }));

    return (
        <div>
            <Header as="h2">Module Status</Header>
            {testStatuses.length === 0 ? (
                <Message info>No tests found in this module.</Message>
            ) : (
                <>
                    <Tab panes={panes} />
                    {renderSubmissionModal()}
                </>
            )}
        </div>
    );
};

export default ViewStatus;