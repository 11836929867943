import React, { useState } from 'react';
import { Button, Message, Container, Header } from 'semantic-ui-react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { studentsData } from '../data/students';

interface Result {
  successful: Array<{email: string, name: string}>;
  failed: Array<{email: string, name: string, error: string}>;
}

const BulkCreateStudents: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<Result | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleCreateStudents = async () => {
    setLoading(true);
    setError(null);
    setResult(null);

    try {
      const functions = getFunctions();
      const bulkCreateStudents = httpsCallable(functions, 'bulkCreateStudents');
      
      const response = await bulkCreateStudents({ students: studentsData });
      setResult(response.data as Result);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header as="h2">Bulk Create Student Accounts</Header>
      
      <Button 
        primary 
        loading={loading}
        onClick={handleCreateStudents}
        disabled={loading}
      >
        Create Student Accounts
      </Button>

      {error && (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{error}</p>
        </Message>
      )}

      {result && (
        <>
          <Message positive>
            <Message.Header>Successfully Created</Message.Header>
            <Message.List>
              {result.successful.map(student => (
                <Message.Item key={student.email}>
                  {student.name} ({student.email})
                </Message.Item>
              ))}
            </Message.List>
          </Message>

          {result.failed.length > 0 && (
            <Message negative>
              <Message.Header>Failed to Create</Message.Header>
              <Message.List>
                {result.failed.map(student => (
                  <Message.Item key={student.email}>
                    {student.name} ({student.email}): {student.error}
                  </Message.Item>
                ))}
              </Message.List>
            </Message>
          )}
        </>
      )}
    </Container>
  );
};

export default BulkCreateStudents; 