export interface Student {
  firstName: string;
  lastName: string;
  initiatedName: string;
  email: string;
  phoneNumber: string;
}

export const studentsData: Student[] = [
    {
      firstName: "Advik",
      lastName: "Gupta",
      initiatedName: "",
      email: "granu2k@gmail.com",
      phoneNumber: "2142954234"
    },
    {
      firstName: "Anandram",
      lastName: "Peddibhotla",
      initiatedName: "",
      email: "anandrampeddibhotla@gmail.com",
      phoneNumber: "4703387370"
    },
    {
      firstName: "Charmi",
      lastName: "Patel",
      initiatedName: "",
      email: "charmipatel004478@gmail.com",
      phoneNumber: "4696181677"
    },
    {
      firstName: "Jignesh",
      lastName: "Soni",
      initiatedName: "",
      email: "jigusoni123@gmail.com",
      phoneNumber: "7083371635"
    },
    {
      firstName: "Kapil",
      lastName: "Sharma",
      initiatedName: "",
      email: "kapilsharma36@outlook.com",
      phoneNumber: "6262269642"
    },
    {
      firstName: "Manoj Kumar",
      lastName: "Mohanty",
      initiatedName: "",
      email: "manojoct2@gmail.com",
      phoneNumber: "6822561125"
    },
    {
      firstName: "neeraj",
      lastName: "Garg",
      initiatedName: "",
      email: "neeraj_garg@yahoo.com",
      phoneNumber: "9727683436"
    },
    {
      firstName: "Nilam",
      lastName: "Soni",
      initiatedName: "",
      email: "nilamsoni25@yahoo.com",
      phoneNumber: "8477497853"
    },
    {
      firstName: "Priyanka",
      lastName: "Jidagamu",
      initiatedName: "N/A",
      email: "jidagamupriyan@gmail.com",
      phoneNumber: "2244012980"
    },
    {
      firstName: "Radhe",
      lastName: "Bearden",
      initiatedName: "",
      email: "radhevb108@gmail.com",
      phoneNumber: "2149608598"
    },
    {
      firstName: "Romil",
      lastName: "Bansal",
      initiatedName: "",
      email: "romilbansal@gmail.com",
      phoneNumber: "4085070673"
    },
    {
      firstName: "Ruhee",
      lastName: "Soni",
      initiatedName: "",
      email: "ruheesoni123@gmail.com",
      phoneNumber: "7083357188"
    },
    {
      firstName: "Sahana",
      lastName: "Somanna",
      initiatedName: "",
      email: "mailsunilsomanna@gmail.com",
      phoneNumber: "5108095460"
    },
    {
      firstName: "Samriddhi",
      lastName: "Lamichhane",
      initiatedName: "",
      email: "samriddhilamichhane701@gmail.com",
      phoneNumber: "8178758834"
    },
    {
      firstName: "Sangeeta",
      lastName: "Patel",
      initiatedName: "",
      email: "sarangp595@yahoo.com",
      phoneNumber: "2142028278"
    },
    {
      firstName: "Sindhu",
      lastName: "Samayamantula",
      initiatedName: "",
      email: "samaysindhu28@gmail.com",
      phoneNumber: "6822563153"
    },
    {
      firstName: "Sudip",
      lastName: "Aryal",
      initiatedName: "NA",
      email: "sudiparyal530@gmail.com",
      phoneNumber: "6692907200"
    },
    {
      firstName: "Tulika",
      lastName: "Berger",
      initiatedName: "Tulasi Sakhi dd",
      email: "tulika_sahoo@yahoo.com",
      phoneNumber: "7203266433"
    }
]; 