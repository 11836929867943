import React, { useState, useEffect } from 'react';
import { Container, Grid, Header, Image, Card, Button, Label } from 'semantic-ui-react';
import { collection, getDocs, limit, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase/config'; // Make sure this path is correct
import logo from '../assets/logo.jpg';

interface Course {
  id: string;
  name: string;
  description: string;
  courseFee: number;
  imageUrl: string;
  startDate: Date;
  endDate: Date;
  difficulty: string;
}

const Home: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);

  useEffect(() => {
    const fetchCourses = async () => {
      const coursesQuery = query(
        collection(db, 'courses'),
        orderBy('createdAt', 'desc'),
        limit(3)
      );
      const querySnapshot = await getDocs(coursesQuery);
      const fetchedCourses = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        startDate: doc.data().startDate?.toDate(),
        endDate: doc.data().endDate?.toDate(),
      } as Course));
      setCourses(fetchedCourses);
    };

    fetchCourses();
  }, []);

  return (
    <Container>
      {/* Hero Section */}
      <Grid style={{ marginTop: '2em', marginBottom: '2em' }}>
        <Grid.Row>
          <Grid.Column width={8} verticalAlign='middle'>
            <Header as='h1' textAlign='center'>
              Welcome to Bhaktivedanta Vedic Academy's
              <Header.Subheader 
                as='h2' 
                style={{ 
                  fontSize: '1.5em', 
                  color: '#4183c4', 
                  marginTop: '0.5em' 
                }}
              >
                Bhakti Sastri Course
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <Image src={logo} size='large' floated='right' />
          </Grid.Column>
        </Grid.Row>
      </Grid>      
    </Container>
  );
};

export default Home;